<template>
  <div>
    <b-modal
      v-model="modalshow"
      :title="titleModal"
      :hide-footer="true"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      :busy="isBusy"
      :ok-disabled="true"
    >
      <div class="text-center">
        <span class="ml-25 d-block pb-2 text-white"><strong>{{ subTitle }}</strong></span>
        <img
          v-if="srcQR !== null"
          :src="srcQR"
          width="400"
        >
        <b-button
          v-else
          variant="primary"
          @click="generateQR()"
        >
          <span class="text-nowrap">Generar Código QR</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'
import {
  ref,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import config from '@/services/config'
import { showToast, showError } from '@/helpers'

export default {
  components: {
    BModal,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const modalshow = ref(false)
    const isBusy = ref(false)
    const { pathRepo, contextBO } = config
    const pathMultimedia = ref(`${pathRepo}${contextBO}/api/v1/multimedia`)
    const idDestino = ref(0)
    const seccion = ref('')
    const idSeccion = ref(0)
    const titleModal = ref('Código QR: ')
    const srcQR = ref('')
    const subTitle = ref('')

    const openModal = () => {
      if (srcQR.value !== null) {
        subTitle.value = 'ESCANEAR CÓDIGO'
        srcQR.value = `${pathMultimedia.value}/byUrl?isthumb=false&url=${srcQR.value}`
      } else {
        subTitle.value = 'SIN CÓDIGO'
      }
      modalshow.value = true
    }

    const generateQR = async () => {
      isBusy.value = true
      await store.dispatch('herramienta/HERRAMIENTA_GET_QR', {
        seccion: seccion.value,
        idSeccion: idSeccion.value,
        idDestino: idDestino.value,
      })
        .then(response => {
          if (response.success) {
            srcQR.value = response.QRCode
          } else {
            srcQR.value = ''
          }
        })
      isBusy.value = false
    }

    return {
      // Customs
      modalshow,
      openModal,
      isBusy,
      pathMultimedia,
      titleModal,
      subTitle,
      srcQR,
      generateQR,
      seccion,
      idSeccion,
      idDestino,
    }
  },
  methods: {
    showToast,
    showError,
  },
}
</script>

<style>
.modal-header{
  align-items: center;
}
.modal-body {
  background-color: #343a40 !important;
  padding-bottom: 60px;
}
</style>
