<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ titleForm }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">
        <b-button-group size="sm">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="saveForm"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <span class="align-middle">Guardar</span>
          </b-button>
        </b-button-group>
      </div>
    </div>

    <b-card id="seccion-content">
      <b-card-body class="p-0">
        <b-form class="pl-0 pr-0">
          <h6 class="text-dark">
            <feather-icon icon="ClipboardIcon" />
            <span class="align-middle ml-25">Ficha Básica</span>
          </h6>

          <hr
            style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
          >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Destino Turístico"
                label-for="destino"
              >
                <v-select
                  id="destino"
                  v-model="destinoSel"
                  label="nombre"
                  :reduce="m => m.nombre"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="destinos"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Tipo Empresa"
                label-for="tipoEmpresa"
              >
                <v-select
                  id="tipoEmpresa"
                  v-model="tipoEmpresaSel"
                  label="labelFrontend"
                  :reduce="m => m.idTipoServicioTuristico"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="tiposEmpresas"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Ruc"
                label-for="ruc"
              >
                <b-form-input
                  id="ruc"
                  v-model="dataRegister.ruc"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Razón Social"
                label-for="razonSocial"
              >
                <b-form-input
                  id="razonSocial"
                  v-model="dataRegister.razonSocial"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Nombre Comercial"
                label-for="nombreComercial"
              >
                <b-form-input
                  id="nombreComercial"
                  v-model="dataRegister.nombreComercial"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Representante Legal"
                label-for="representanteLegal"
              >
                <b-form-input
                  id="representanteLegal"
                  v-model="dataRegister.representanteLegal"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Descripción"
                label-for="descripcion"
              >
                <quill-editor
                  id="descripcion"
                  v-model="dataRegister.descripcion"
                  :options="editorOption"
                  class="border-bottom-0"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h6 class="text-dark">
            <feather-icon icon="MapPinIcon" />
            <span class="align-middle ml-25">Datos de Ubicación</span>
          </h6>

          <hr
            style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
          >

          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Departamento"
                label-for="dpto"
              >
                <v-select
                  v-model="dptoSel"
                  :reduce="m => m.idUbigeo"
                  label="descripcion"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dptos"
                  @input="getUbigeos('prov', dptoSel.slice(0, 2))"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Provincia"
                label-for="prov"
              >
                <v-select
                  v-model="provSel"
                  :reduce="m => m.idUbigeo"
                  label="descripcion"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="provs"
                  @input="getUbigeos('dist', provSel.slice(0, 4))"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Distrito"
                label-for="dist"
              >
                <v-select
                  v-model="distSel"
                  :reduce="m => m.idUbigeo"
                  label="descripcion"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dists"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="8"
            >
              <b-form-group
                label="Dirección"
                label-for="direccion"
              >
                <b-form-input
                  id="direccion"
                  v-model="dataRegister.direccion"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                label="Latitud"
                label-for="latitud"
              >
                <b-form-input
                  id="latitud"
                  v-model="dataRegister.latitud"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                label="Longitud"
                label-for="longitud"
              >
                <b-form-input
                  id="longitud"
                  v-model="dataRegister.longitud"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <h6 class="text-dark">
            <feather-icon icon="PhoneOutgoingIcon" />
            <span class="align-middle ml-25">Datos de Contacto y Canales Digitales</span>
          </h6>

          <hr
            style="border-top: 2px solid #161d31;margin-bottom: 1rem !important;margin-top: 0 !important;"
          >

          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Teléfono"
                label-for="telefono"
              >
                <b-form-input
                  id="telefono"
                  v-model="dataRegister.telefono"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Celular"
                label-for="celular"
              >
                <b-form-input
                  id="celular"
                  v-model="dataRegister.celular"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="dataRegister.email"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Página Web"
                label-for="sitioWeb"
              >
                <b-form-input
                  id="sitioWeb"
                  v-model="dataRegister.sitioWeb"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Facebook"
                label-for="facebook"
              >
                <b-form-input
                  id="facebook"
                  v-model="dataRegister.facebook"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Youtube"
                label-for="youtube"
              >
                <b-form-input
                  id="youtube"
                  v-model="dataRegister.youtube"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Instagram"
                label-for="instagram"
              >
                <b-form-input
                  id="instagram"
                  v-model="dataRegister.instagram"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Twitter"
                label-for="twitter"
              >
                <b-form-input
                  id="twitter"
                  v-model="dataRegister.twitter"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import {
  BButtonGroup, BButton, BRow, BCol, BCard, BCardBody, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BButtonGroup,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,

    vSelect,
    quillEditor,
  },
  props: {
    dataEdit: {
      type: Object,
      required: true,
    },
    typeForm: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const titleForm = ref('Agregar Empresa')
    const dataRegister = ref({})
    const isBusy = ref(false)
    const destinoSel = ref({})
    const destinos = ref([])
    const tipoEmpresaSel = ref({})
    const tiposEmpresas = ref([])
    const dptoSel = ref('220000')
    const provSel = ref(null)
    const distSel = ref(null)
    const dptos = ref([])
    const provs = ref([])
    const dists = ref([])

    const toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      // [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      // [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      // [{ font: [] }],
      // ['clean'],
    ]

    const editorOption = {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: 'Ingrese descripción',
    }

    const getDestinos = async () => {
      await store
        .dispatch('catalogo/DESTINO_FIND_ALL', {
          query: '',
          page: -1,
          limit: -1,
          sortBy: '',
        })
        .then(response => {
          if (response.items) {
            destinos.value = response.items
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const getTiposEmpresas = async () => {
      await store.dispatch('catalogo/TIPO_SERVICIO_TURISTICO_FIND_ALL', {
        query: '',
        page: 1,
        limit: -1,
        sortBy: 'idTipoServicioTuristico%7CASC',
      })
        .then(response => {
          if (response) {
            tiposEmpresas.value = response.items
          }
        })
    }

    const getUbigeos = async (type, codUbigeo) => {
      await store
        .dispatch('catalogo/UBIGEO_FIND_ALL', {
          get: type,
          codUbigeo,
        })
        .then(response => {
          if (type === 'dpto') {
            dptos.value = response
            provSel.value = null
            distSel.value = null
            provs.value = []
            dists.value = []
          }
          if (type === 'prov') {
            provs.value = response
            provSel.value = null
            distSel.value = null
            dists.value = []
          }
          if (type === 'dist') {
            dists.value = response
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const saveForm = () => {
      console.log('vamos a grabar')
    }

    const loadData = async () => {
      await getDestinos()
      await getTiposEmpresas()
      await getUbigeos('dpto', '')
      if (props.typeForm === 'edit') {
        titleForm.value = 'Editar Empresa'
        dataRegister.value = props.dataEdit
        destinoSel.value = dataRegister.value.destino
        tipoEmpresaSel.value = dataRegister.value.tipoServicioTuristico
      } else {
        dataRegister.value = {}
        destinoSel.value = {}
        tipoEmpresaSel.value = {}
        titleForm.value = 'Agregar Empresa'
      }
    }

    loadData()

    return {
      titleForm,
      isBusy,
      dataRegister,
      getDestinos,
      destinos,
      destinoSel,
      tipoEmpresaSel,
      tiposEmpresas,
      dptoSel,
      provSel,
      distSel,
      dptos,
      provs,
      dists,
      getUbigeos,
      editorOption,
      saveForm,
    }
  },
}
</script>

<style>

</style>
