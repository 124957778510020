<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <modal-qr
      ref="refModalQr"
    />
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Buscar por razón social, ruc y otros datos"
              @input="updateRouteQuery"
            />
          </b-input-group>
        </div>
      </div>

      <!-- App Action Bar -->
      <div class="app-action">
        <div class="action-left">
          <b-form-checkbox
            :checked="selectAllEmailCheckbox"
            :indeterminate="isSelectAllEmailCheckboxIndeterminate"
            @change="selectAllCheckboxUpdate"
          >
            Seleccionar todo
          </b-form-checkbox>
        </div>
        <div
          v-show="selectedEmails.length"
          class="align-items-center"
          :class="{'d-flex': selectedEmails.length}"
        >

          <!-- Update Mail Folder Dropdown -->
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <feather-icon
                icon="FolderIcon"
                size="17"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="moveSelectedEmailsToFolder('draft')">
              <feather-icon icon="Edit2Icon" />
              <span class="align-middle ml-50">Draft</span>
            </b-dropdown-item>

            <b-dropdown-item @click="moveSelectedEmailsToFolder('spam')">
              <feather-icon icon="InfoIcon" />
              <span class="align-middle ml-50">Spam</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-show="$route.params.folder !== 'trash'"
              @click="moveSelectedEmailsToFolder('trash')"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Trash</span>
            </b-dropdown-item>
          </b-dropdown>

          <!-- Update Mail Folder Dropdown -->
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            class="ml-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="TagIcon"
                size="17"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelectedEmailsLabel('personal')">
              <span class="mr-50 bullet bullet-success bullet-sm" />
              <span>Personal</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updateSelectedEmailsLabel('company')">
              <span class="mr-50 bullet bullet-primary bullet-sm" />
              <span>Company</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updateSelectedEmailsLabel('important')">
              <span class="mr-50 bullet bullet-warning bullet-sm" />
              <span>Important</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updateSelectedEmailsLabel('private')">
              <span class="mr-50 bullet bullet-danger bullet-sm" />
              <span>Private</span>
            </b-dropdown-item>
          </b-dropdown>

          <feather-icon
            icon="MailIcon"
            size="17"
            class="cursor-pointer ml-1"
            @click="markSelectedEmailsAsUnread"
          />

          <feather-icon
            v-show="$route.params.folder !== 'trash'"
            icon="TrashIcon"
            size="17"
            class="cursor-pointer ml-1"
            @click="moveSelectedEmailsToFolder('trash')"
          />

        </div>
      </div>

      <!-- Emails List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <ul class="email-media-list">
          <b-media
            v-for="empresa in empresas"
            :key="empresa.idDirectorio"
            tag="li"
            no-body
            :class="{ 'mail-read': empresa.estado }"
            @click="updateEmailViewData(empresa)"
          >

            <b-media-aside class="media-left mr-50">
              <b-avatar
                rounded
                class="avatar"
                size="70"
                variant="primary"
                :src="empresa.multimedia.length > 0 ? `${pathMultimedia}/byUrl?isthumb=true&url=${empresa.multimedia[0].url}` : '0'"
              />
            </b-media-aside>

            <b-media-body>
              <div class="mail-details">
                <div class="mail-items">
                  <h5 class="mb-25">
                    {{ empresa.nombreComercial }}
                  </h5>
                  <b-badge
                    variant="light-secondary"
                    class="mr-50"
                  >
                    {{ empresa.destino.nombre }}
                  </b-badge>
                  <b-badge
                    variant="light-secondary"
                    class="mr-50"
                  >
                    {{ empresa.tipoServicioTuristico.labelFrontend }}
                  </b-badge>
                </div>
                <div class="mail-meta-item">
                  <b-button-toolbar justify>
                    <b-button-group size="sm">
                      <b-button
                        v-b-tooltip.hover.top="'Editar'"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary"
                        @click="editEmpresa(empresa)"
                      >
                        <feather-icon icon="EditIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="'Código QR'"
                        :variant="empresa.qrCode !== null ? 'info' : 'secondary'"
                        class="btn-wishlist remove-wishlist"
                        @click="getModalQr(empresa)"
                      >
                        <feather-icon icon="GridIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="'Imágenes Destacadas'"
                        variant="warning"
                        class="btn-wishlist remove-wishlist"
                        @click="imagesRegister(empresa)"
                      >
                        <feather-icon icon="ImageIcon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="'Eliminar'"
                        variant="danger"
                        class="btn-cart move-cart"
                        @click="deleteRegister(empresa)"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                          class="mr-50"
                        />
                      </b-button>
                    </b-button-group>
                  </b-button-toolbar>
                </div>
              </div>

              <div class="mail-message">
                <span class="mail-date align-middle">
                  <feather-icon
                    icon="StarIcon"
                    size="14"
                    :class="{ 'text-warning fill-current': empresa.destacado }"
                    @click.stop="toggleStarred(empresa)"
                  />
                  Destacado
                </span>
                <span class="mail-date align-middle">
                  <feather-icon
                    icon="PhoneCallIcon"
                    size="14"
                    class="text-secondary"
                    @click.stop="toggleStarred(empresa)"
                  />
                  {{ empresa.telefono }}
                </span>
                <span class="mail-date align-middle">
                  <feather-icon
                    icon="MailIcon"
                    size="14"
                    class="text-secondary"
                    @click.stop="toggleStarred(empresa)"
                  />
                  {{ empresa.email }}
                </span>
              </div>
            </b-media-body>
          </b-media>
        </ul>
        <div
          class="no-results"
          :class="{'show': !empresas.length}"
        >
          <h5>No Items Found</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Email View/Detail -->
    <empresa-view
      v-if="isReady"
      :class="{'show': showEmailDetails}"
      :data-edit="dataEdit"
      :type-form="typeForm"
      @close-email-view="showEmailDetails = false"
      @move-email-to-folder="moveOpenEmailToFolder"
      @toggle-email-starred="toggleStarred(emailViewData)"
      @update-email-label="updateOpenEmailLabel"
      @mark-email-unread="markOpenEmailAsUnread"
      @change-opened-email="changeOpenedEmail"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <empresa-left-sidebar
        :tipos-empresa="tiposEmpresa"
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :emails-meta="emailsMeta"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
        @open-new-register="newEmpresa"
      />
    </portal>

    <!-- Compose Email Modal -->
    <empresa-compose v-model="shallShowEmailComposeModal" />
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, computed, watch,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BButton, VBTooltip,
  BFormCheckbox, BMedia, BMediaAside, BMediaBody, BAvatar, BButtonToolbar, BButtonGroup, BBadge,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import config from '@/services/config'
import EmpresaLeftSidebar from './EmpresaLeftSidebar.vue'
import EmpresaView from './EmpresaView.vue'
import empresaStoreModule from './empresaStoreModule'
import useEmpresa from './useEmpresa'
import EmpresaCompose from './EmpresaCompose.vue'
import ModalQr from '../code_qr/ModalQr.vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BBadge,

    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    EmpresaLeftSidebar,
    EmpresaView,
    EmpresaCompose,
    ModalQr,
  },
  setup() {
    const EMAIL_APP_STORE_MODULE_NAME = 'app-email'
    const tiposEmpresa = ref([])
    const empresas = ref([])
    const { pathRepo, contextBO } = config
    const pathMultimedia = ref(`${pathRepo}${contextBO}/api/v1/multimedia`)
    const dataEdit = ref({})
    const typeForm = ref('')
    const isReady = ref(false)
    const refModalQr = ref(null)

    const getTiposEmpresas = async () => {
      await store.dispatch('catalogo/TIPO_SERVICIO_TURISTICO_FIND_ALL', {
        query: '',
        page: 1,
        limit: -1,
        sortBy: 'idTipoServicioTuristico%7CASC',
      })
        .then(response => {
          if (response) {
            tiposEmpresa.value = response.items
          }
        })
    }

    const getModalQr = item => {
      refModalQr.value.idSeccion = item.idDirectorio
      refModalQr.value.idDestino = item.destino.idDestinoTuristico
      refModalQr.value.seccion = 'Directorio'
      refModalQr.value.srcQR = item.qrCode
      refModalQr.value.openModal()
    }

    // Register module
    if (!store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.registerModule(EMAIL_APP_STORE_MODULE_NAME, empresaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.unregisterModule(EMAIL_APP_STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const { resolveLabelColor } = useEmpresa()

    // Route Params
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      getEmpresas()
    })

    // Emails & EmailsMeta
    const emails = ref([])
    const emailsMeta = ref({})

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Search Query
    const routeQuery = computed(() => route.value.query.q)
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch(searchQuery, () => getEmpresas())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const getEmpresas = async () => {
      await store.dispatch('plataforma/EMPRESA_FIND_ALL', {
        query: searchQuery.value !== undefined ? searchQuery.value : '',
        page: 1,
        limit: 50,
        sortBy: 'idDirectorio%7CASC',
        idDestino: -1,
        idTipoDirectorio: -1,
        urlSlugDirectorio: router.currentRoute.params.urlSlug || '',
      })
        .then(response => {
          if (response) {
            empresas.value = response.items
          }
        })
    }

    const fetchEmails = () => {
      store.dispatch('app-email/fetchEmails', {
        q: searchQuery.value,
        folder: router.currentRoute.params.folder || 'inbox',
        label: router.currentRoute.params.label,
      })
        .then(response => {
          emails.value = response.data.emails
          emailsMeta.value = response.data.emailsMeta
        })
    }
    getTiposEmpresas()
    getEmpresas()
    fetchEmails()

    // ------------------------------------------------
    // Mail Selection
    // ------------------------------------------------
    const selectedEmails = ref([])
    const toggleSelectedMail = mailId => {
      const mailIndex = selectedEmails.value.indexOf(mailId)

      if (mailIndex === -1) selectedEmails.value.push(mailId)
      else selectedEmails.value.splice(mailIndex, 1)
    }
    const selectAllEmailCheckbox = computed(() => emails.value.length && (emails.value.length === selectedEmails.value.length))
    const isSelectAllEmailCheckboxIndeterminate = computed(() => Boolean(selectedEmails.value.length) && emails.value.length !== selectedEmails.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedEmails.value = val ? emails.value.map(mail => mail.id) : []
    }

    // ------------------------------------------------
    // Mail Actions
    // ------------------------------------------------
    const toggleStarred = email => {
      store.dispatch('app-email/updateEmail', {
        emailIds: [email.id],
        dataToUpdate: { isStarred: !email.isStarred },
      }).then(() => {
        // eslint-disable-next-line no-param-reassign
        email.isStarred = !email.isStarred
      })
    }

    const moveSelectedEmailsToFolder = folder => {
      store.dispatch('app-email/updateEmail', {
        emailIds: selectedEmails.value,
        dataToUpdate: { folder },
      })
        .then(() => { fetchEmails() })
        .finally(() => { selectedEmails.value = [] })
    }

    const updateSelectedEmailsLabel = label => {
      store.dispatch('app-email/updateEmailLabels', {
        emailIds: selectedEmails.value,
        label,
      })
        .then(() => { fetchEmails() })
        .finally(() => { selectedEmails.value = [] })
    }

    const markSelectedEmailsAsUnread = () => {
      store.dispatch('app-email/updateEmail', {
        emailIds: selectedEmails.value,
        dataToUpdate: { isRead: false },
      })
        .then(() => { fetchEmails() })
        .finally(() => { selectedEmails.value = [] })
    }

    // ------------------------------------------------
    // Email Details
    // ------------------------------------------------
    const showEmailDetails = ref(false)
    const emailViewData = ref({})
    const opendedEmailMeta = computed(() => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
      return {
        hasNextEmail: Boolean(emails.value[openedEmailIndex + 1]),
        hasPreviousEmail: Boolean(emails.value[openedEmailIndex - 1]),
      }
    })

    const newEmpresa = () => {
      isReady.value = false
      typeForm.value = 'new'
      isReady.value = true
      showEmailDetails.value = true
    }

    const editEmpresa = item => {
      dataEdit.value = item
      typeForm.value = 'edit'
      isReady.value = true
      showEmailDetails.value = true
    }

    const updateEmailViewData = email => {
      // Mark email is read
      store.dispatch('app-email/updateEmail', {
        emailIds: [email.id],
        dataToUpdate: { isRead: true },
      })
        .then(() => {
          // If opened email is unread then decrease badge count for email meta based on email folder
          if (!email.isRead && (email.folder === 'inbox' || email.folder === 'spam')) {
            emailsMeta.value[email.folder] -= 1
          }

          // eslint-disable-next-line no-param-reassign
          email.isRead = true
        })
        .finally(() => {
          emailViewData.value = email
          showEmailDetails.value = true
        })
    }
    const moveOpenEmailToFolder = folder => {
      selectedEmails.value = [emailViewData.value.id]
      moveSelectedEmailsToFolder(folder)
      selectedEmails.value = []
      showEmailDetails.value = false
    }
    const updateOpenEmailLabel = label => {
      selectedEmails.value = [emailViewData.value.id]
      updateSelectedEmailsLabel(label)

      // Update label in opened email
      const labelIndex = emailViewData.value.labels.indexOf(label)
      if (labelIndex === -1) emailViewData.value.labels.push(label)
      else emailViewData.value.labels.splice(labelIndex, 1)

      selectedEmails.value = []
    }

    const markOpenEmailAsUnread = () => {
      selectedEmails.value = [emailViewData.value.id]
      markSelectedEmailsAsUnread()

      selectedEmails.value = []
      showEmailDetails.value = false
    }

    const changeOpenedEmail = dir => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
      const newEmailIndex = dir === 'previous' ? openedEmailIndex - 1 : openedEmailIndex + 1
      emailViewData.value = emails.value[newEmailIndex]
    }

    // * If someone clicks on filter while viewing detail => Close the email detail view
    watch(routeParams, () => {
      showEmailDetails.value = false
    })

    // * Watcher to reset selectedEmails
    // ? You can also use showEmailDetails (instead of `emailViewData`) but it will trigger execution twice in this case
    // eslint-disable-next-line no-use-before-define
    watch([emailViewData, routeParams], () => {
      selectedEmails.value = []
    })

    // Compose
    const shallShowEmailComposeModal = ref(false)

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      refModalQr,
      tiposEmpresa,
      empresas,
      pathMultimedia,
      newEmpresa,
      editEmpresa,
      getModalQr,
      dataEdit,
      typeForm,
      isReady,
      // UI
      perfectScrollbarSettings,

      // Emails & EmailsMeta
      emails,
      emailsMeta,

      // Mail Selection
      selectAllEmailCheckbox,
      isSelectAllEmailCheckboxIndeterminate,
      selectedEmails,
      toggleSelectedMail,
      selectAllCheckboxUpdate,

      // Mail Actions
      toggleStarred,
      moveSelectedEmailsToFolder,
      updateSelectedEmailsLabel,
      markSelectedEmailsAsUnread,

      // Email Details
      showEmailDetails,
      emailViewData,
      opendedEmailMeta,
      updateEmailViewData,
      moveOpenEmailToFolder,
      updateOpenEmailLabel,
      markOpenEmailAsUnread,
      changeOpenedEmail,

      // Search Query
      searchQuery,
      updateRouteQuery,

      // UI Filters
      filterTags,
      formatDateToMonthShort,

      // useEmail
      resolveLabelColor,

      // Compose
      shallShowEmailComposeModal,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";

.email-application .content-area-wrapper .email-user-list .email-media-list li .avatar img {
  width: 100% !important;
  height: 100% !important;
}

.email-application .content-area-wrapper .email-user-list .email-media-list li .avatar {
  margin-bottom: 0px !important;
}
</style>
